<template>
  <div id="statistics-view">
    <div class="statistics-title"><h1>Statistics</h1></div>
    <TripCountSection class="col offset-lg-2 col-lg-8"></TripCountSection>
  </div>
</template>

<script>
import TripCountSection from "@/components/TripCountSection";

export default {
  name: "StatisticsView",
  components: {
    TripCountSection
  }
};
</script>

<style scoped>
#statistics-view {
  padding: 2rem 1rem;
}

.statistics-title {
  letter-spacing: 1px;
}
</style>
