<template>
  <div id="trip-count-section">
    <b-container>
      <b-row>
        <b-overlay :show="loadingPostedEmails" class="w-100 min-w-0">
          <b-card class="trips-count-section mt-4 col rounded shadow">
            <template>
              <div class="mb-4">
                <strong class="text-left">
                  Pick a date range to see the truck postings count
                </strong>
              </div>
              <b-row class="mt-4">
                <b-input
                  class="col col-4"
                  type="date"
                  v-model="fromDate"
                ></b-input>
                <b-input
                  class="col offset-1 col-4"
                  type="date"
                  v-model="toDate"
                ></b-input>
                <b-button
                  class="offset-1 col-2"
                  size="sm"
                  variant="info"
                  @click="getCount"
                >
                  Search
                </b-button>
              </b-row>
            </template>
            <div class="mt-4">
              <template
                v-if="postedEmailsData !== null && postedEmailsData.length > 0"
              >
                <DataTable
                  :key="renderTable"
                  :value="postedEmailsData"
                  :lazy="true"
                  responsiveLayout="scroll"
                >
                  <Column
                    v-for="col of columns"
                    :field="col.field"
                    :header="col.header"
                    :key="col.field"
                  ></Column>
                </DataTable>
                <b-pagination
                  v-model="currentPage"
                  size="sm"
                  :total-rows="postedEmailCount"
                  :per-page="perPage"
                  @change="paginate"
                  aria-controls="my-table"
                ></b-pagination>
                <div class="alert alert-info mt-4">
                  <strong>
                    Total of processed emails:
                    {{ postedEmailCount }}
                  </strong>
                  <strong>
                    | Total number of posted trucks: {{ totalPostings }}
                  </strong>
                </div>
              </template>
              <span
                v-else-if="postedEmailsData !== null && postedEmailCount === 0"
              >
                No truck postings in this date range
              </span>
            </div>
            <div v-if="errorsOcurred" class="error-section text-danger">
              {{ errorDescription }}
            </div>
          </b-card>
        </b-overlay>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default {
  name: "TripCountSection",
  components: {
    DataTable,
    Column
  },
  data() {
    return {
      fromDate: moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
      toDate: moment()
        .add(1, "days")
        .format("YYYY-MM-DD"),
      loadingPostedEmails: false,
      errorsOcurred: false,
      errorDescription: null,
      postedEmailCount: 0,
      currentPage: 1,
      perPage: 10,
      renderTable: 0,
      postedEmailsData: null,
      columns: [
        { field: "id", header: "Id" },
        { field: "subject", header: "Subject" },
        { field: "received_at", header: "Received at" },
        { field: "truck_posted_count", header: "Truck posted count" }
      ]
    };
  },
  // watch: {
  //   fromDate(newVal) {
  //     if (this.fromDate !== "" && this.toDate !== "") {
  //       this.getCount(newVal, this.toDate);
  //     }
  //   },
  //   toDate(newVal) {
  //     if (this.fromDate !== "" && this.toDate !== "") {
  //       this.getCount(this.fromDate, newVal);
  //     }
  //   }
  // },
  computed: {
    ...mapGetters("statistics", ["postedEmails"]),
    totalPostings() {
      return this.postedEmails.reduce(
        (sum, email) => sum + email["truck_posted_count"],
        0
      );
    }
  },
  watch: {
    async postedEmails() {
      this.renderTable += 1;
      this.postedEmailsData = this.postedEmails;
    }
  },
  destroyed() {
    this.$store.commit("statistics/SET_POSTED_EMAILS", null);
  },
  methods: {
    ...mapActions("statistics", ["getPostedEmails"]),
    async getCount() {
      try {
        if (this.fromDate !== "" && this.toDate !== "") {
          this.errorsOcurred = false;
          this.loadingPostedEmails = true;
          this.errorDescription = null;
          const params = {};
          params.from_date = this.fromDate;
          params.to_date = this.toDate;
          params.limit = this.perPage;
          params.skip = (this.currentPage - 1) * this.perPage;
          const response = await this.getPostedEmails(params);
          this.postedEmailCount = response?.data.count;
        } else throw new Error("Invalid dates");
      } catch (err) {
        this.errorsOcurred = true;
        console.error(err.message);
        if (err.response && err.response.status && err.response.status === 422)
          this.errorDescription = "Invalid date format";
        else this.errorDescription = err.message;
      } finally {
        this.loadingPostedEmails = false;
        setTimeout(() => (this.errorsOcurred = false), 3000);
      }
    },
    async paginate(pageNumber) {
      this.currentPage = pageNumber;
      await this.getCount();
      window.scrollTo(0, 0);
    }
  }
};
</script>
